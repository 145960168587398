$('#nav-toggle').click(function(){
    $('body').toggleClass('menu-open');
    $(this).toggleClass('active');
} );

$(document).on('click', '#filters-toggle',  function(event) {

    if( $('.shop-filters-toggle').is(":visible") ){
        $(this).text('Hide');
    }else{
        $(this).text('Show');
    }
});

$('.contributors-slider').slick({
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
    ]
}).on('setPosition', function (event, slick) {
    slick.$slides.css('height', slick.$slideTrack.height() + 'px');
});

$('.shop-templates-slider').slick({
  infinite: true,
  arrows: true,
  responsive: [
      {
        breakpoint: 9999,
        settings: "unslick"
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
  ]
});
$('.flag-templates-slider').slick({
    infinite: true,
    arrows: true,
    responsive: [
        {
          breakpoint: 4000,
            settings: "unslick"
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
            }
        }
    ]
});
$('.air-set-slider').slick({
    infinite: true,
    arrows: true,
    responsive: [
        {
            breakpoint: 4000,
            settings: "unslick"
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
            }
        }
    ]
});
$('.contributor-profile-slider').slick({
  infinite: true,
  arrows: true,
  responsive: [
      {
        breakpoint: 9999,
        settings: "unslick"
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
  ]
});

// $('.airgreements-slider').slick({
//   infinite: true,
//   arrows: true,
//   responsive: [
//       {
//         breakpoint: 9999,
//         settings: "unslick"
//       },
//       {
//         breakpoint: 576,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         }
//       }
//   ]
// });



$('.show-password').click(function(e){
	e.preventDefault();
	var p = $(this).siblings('input');
  if($(p).prop('type') == 'password'){
    $(p).prop('type', 'text');
    $(this).find('i').removeClass('fa-eye');
    $(this).find('i').addClass('fa-eye-slash');
	}else{
    $(p).prop('type', 'password');
    $(this).find('i').removeClass('fa-eye-slash');
    $(this).find('i').addClass('fa-eye');
	}
	return false;
});

$(function () {
  $('[data-toggle="tooltip"]').tooltip({
    offset: '10px, 10px'
  });
})

$(document).ready(function() {
  if(window.location.hash) {
    var hash = window.location.hash.substr(1);
    if ( $( "#"+hash ).length ) {
      $("#"+hash).modal('show');
    }
  }
});

$('.activator').click(function(e){
  e.preventDefault();
  if( $(this).hasClass('active') ){
    $(this).removeClass('active');
    $(this).removeClass('coral-out');
    $(this).addClass('green');
    $(this).text('Activate');
    $(this).next('span').text('Inactive');
  }else{
    $(this).addClass('active');
    $(this).text('Deactivate');
    $(this).removeClass('green');
    $(this).addClass('coral-out');
    $(this).next('span').text('Active');
  }
});

$('.bs-datepicker').datepicker({
});


$.mask.definitions['a'] = "[0-1]";
$.mask.definitions['b'] = "[0-2]";
$.mask.definitions['d'] = "[0-5]";
$.mask.definitions['e'] = "[0-9]";
$('.form-time12').mask("ae:de");
$('.form-time24').mask("be:de");



$(window).on("scroll", function() {
  var top = $(window).scrollTop();
    if ( document.body.scrollTop > 120 || document.documentElement.scrollTop > 120 ) {
      $('#to-top').show();
    } else {
      $('#to-top').hide();
    }
});

$('#to-top').click(function(e){
  e.preventDefault();

  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  /*
    $('html, body').animate({
      scrollTop: $("#header").offset().top
    }, 500);
    */
});