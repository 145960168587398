
//to be replaced by vue at some point, maybe.....
// up its starteing to become legacy
$(document).ready(function() {
  //these are a hack to automaticaly show any system errors or messages 
  $("#modal-sytesmfeedback-error,#modal-sytesmfeedback-success").modal('show'); 
   
  //------------------------------------[the generic ajax]
  $("#modals-box" ).on( "click",".modal-action-button",function(){
    console.log('this action')
    var self = $(this);
    console.log(self);
    $.ajax({
      type: "GET",
      url: "/ajax",
      data:{
        id:self.data('id'),
        payload:self.data('payload'),
      },
      beforeSend: function(){
        $('.modal').modal('hide');
        $('#proccessing-modal').modal('show');
      },
      success: function(data){
        console.log('hi')
        $('.modal').modal('hide');
        if(data.type == 'error'){
          $('#error-modal').find('.message').html(data.msg)
          $('#error-modal').modal('show');
        }else{
          $('#success-modal').find('.message').html(data.msg)
          $('#success-modal').modal('show');
          //location.reload();
        }
      }
    });
  });
  
  //----------------------------------------------------[this has todo with starting a contract ]
  $("#start-contract-button").click(function(){$('#start-contract-modal').modal('show'); });
  $( "#start-contract-modal" ).on( "click", "#proccess-contract-button", function() {
    $('#start-contract-modal').modal('hide');
    $('#proccessing-modal').modal('show');
    var foo = '';
    $('#party-members div').each(function(k,v){foo = foo+$(v).data('id')+','; });
    $('#contract-form input[name=members]').val(foo);
    $('#contract-form').submit();
  });

  //------------------------------------[contract form stuffs]
  $( "#edit-button" ).click(function() {
     $(".flip-showen").hide();
     $(".flip-hidden").show();
     $(".buttons-accept").hide();
  });
  $('#contractForm checkbox, #contractForm radio, #contractForm select,#contractForm input,#contractForm textarea').change(function () { 
    $('#edit-div button').removeAttr('disabled');
    $('#edit-div div').slideUp();
    $('#edit-div a').fadeIn();
    $('#edit-div .small-text').fadeOut();
  });

   //bit of legacy for the add user vuejs, should be moved
  $('#add-a-party').on('show.bs.modal', function(e) {
    $(this).find('#user-search-key').val($(e.relatedTarget).data('key')) 
    $(this).find('#add-users-button').data('key', $(e.relatedTarget).data('contract')) 
  });

  //------------------------------------[modal data population functions]
  $('#offer-withdraw, #user-delete').on('show.bs.modal', function(e) {
    console.log('are we used')
    $(this).find('.title').html($(e.relatedTarget).data('title'))
    $(this).find('.modal-action-button').data('id', $(e.relatedTarget).data('id'));
  });

  //------------------------------------[generic actions]
  $( ".modal" ).on( "click", ".close-button", function() {$( ".modal" ).modal('hide'); });
  
} );



